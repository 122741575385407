// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]5.6-rc-9714(8d051302cc)-C14/11/2023-09:50:30-B14/11/2023-09:53:56' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]5.6-rc-9714(8d051302cc)-C14/11/2023-09:50:30-B14/11/2023-09:53:56",
  branch: "master",
  latestTag: "5.6-rc",
  revCount: "9714",
  shortHash: "8d051302cc",
  longHash: "8d051302ccca14c3a5d48178dea6d9e4403f34fc",
  dateCommit: "14/11/2023-09:50:30",
  dateBuild: "14/11/2023-09:53:56",
  buildType: "Ansible",
  } ;
